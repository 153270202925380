<template>
  <div>
    <v-btn
      :color="buttonColor"
      :disabled="isDisabled"
      @click="showYesNoDialog = true"
    >
      <v-icon
        left
        dark
        class="mr-1"
      >
        mdi-delete
      </v-icon>
      {{ buttonText }}
    </v-btn>
    <v-dialog
      v-if="showYesNoDialog"
      v-model="showYesNoDialog"
      max-width="800"
    >
      <v-card class="pa-2">
        <v-card-title class="pb-2">
          <span>{{ title }} {{ $lang.labels.success }}</span>
          <v-spacer></v-spacer>
          <v-btn
            icon
            color="primary"
            text
            large
            @click="closeDialog()"
          >
            X
          </v-btn>
        </v-card-title>
        <v-alert
          v-if="formattedErrorsUsages.length || formattedErrorsResources.length || formattedErrorsUsers.length"
          type="error"
          class="mb-2"
        >
          <p v-if="formattedErrorsUsages.length">{{ $lang.labels.connectedResources }}:</p>
          <ul v-if="formattedErrorsUsages.length">
            <li v-for="(error, index) in formattedErrorsUsages" :key="index" style="list-style-type: none">
              <v-icon class="mr-1">{{ iconHandler(error.item.resourceType) }}</v-icon><span class="clickable-simple" @click="openItem(error.item)">{{ error.value }}</span>
            </li>
          </ul>
          <p v-if="formattedErrorsResources.length" class="pt-2">{{ $lang.labels.connectedResources }}:</p>
          <ul v-if="formattedErrorsResources.length">
            <li v-for="(error, index) in formattedErrorsResources" :key="index" style="list-style-type: none">
              <v-icon class="mr-1">{{ iconHandler(error.item.resourceType) }}</v-icon><span class="clickable-simple" @click="openItem(error.item)">{{ error.value }}</span>
            </li>
          </ul>
          <p v-if="formattedErrorsUsers.length" class="pt-2">{{ $lang.labels.connectedUsers }}:</p>
          <ul v-if="formattedErrorsUsers.length">
            <li v-for="(error, index) in formattedErrorsUsers" :key="index" style="list-style-type: none">
              <v-icon class="mr-1">{{ iconHandler(error.item.resourceType) }}</v-icon><span class="clickable-simple" @click="openItem(error.item)">{{ error.value }}</span>
            </li>
          </ul>
        </v-alert>
        <v-alert
          v-if="!formattedErrorsUsages.length && !formattedErrorsResources.length && !formattedErrorsUsers.length && !triggerForceLogic && deleteSuccess"
          type="info"
          class="mb-2"
        >
          <p>{{ $lang.labels.noConnectedResources }}</p>
        </v-alert>
        <v-card-text v-if="triggerForceLogic" class="pb-2">{{ $lang.labels.cantDelete }}</v-card-text>
        <v-card-text v-if="!deleteSuccess && !triggerForceLogic" class="pb-2">{{ actionText }} {{ type }} {{ actionTextSuffix }}?</v-card-text>
        <v-row v-if="!deleteSuccess" no-gutters justify="space-between" align="center">
          <v-btn
            color="error"
            text
            @click="closeDialog()"
          >
            {{ $lang.actions.no }}
          </v-btn>
          <div class="d-inline-flex align-center">
            <v-switch
              v-if="forcedOption"
              v-model="forceDelete"
              color="error"
              class="mr-2"
              :label="$lang.actions.forceDelete"
              style="padding-top: 4px"
            />
            <v-btn
              color="primary"
              text
              @click="submit()"
            >
              {{ $lang.actions.yes }}
            </v-btn>
          </div>
        </v-row>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: () => ''
    },
    title: {
      type: String,
      default: () => ''
    },
    actionText: {
      type: String,
      default: () => ''
    },
    actionTextSuffix: {
      type: String,
      default: () => ''
    },
    buttonText: {
      type: String,
      default: () => ''
    },
    buttonColor: {
      type: String,
      default: () => 'primary'
    },
    isDisabled: {
      type: Boolean,
      default: () => false
    },
    forcedOption: {
      type: Boolean,
      default: () => false
    },
    triggerForceLogic: {
      type: Boolean,
      default: () => false
    },
    regularDeleteErrorsUsages: {
      type: Array,
      default: () => []
    },
    regularDeleteErrorsResources: {
      type: Array,
      default: () => []
    },
    regularDeleteErrorsUsers: {
      type: Array,
      default: () => []
    },
    deleteSuccess: {
      type: Boolean,
      default: () => false
    }
  },
  data () {
    return {
      showYesNoDialog: false,
      forceDelete: false,
      resourcesTypes: {
        GLOBAL_TRANSLATION: 'templates/translations/edit',
        MODULE: 'modules/local/edit',
        PROCESS: 'processes/edit',
        PROCESS_CREDENTIAL: 'credentials/edit',
        SETTING: 'settings/edit',
        TEXT_TEMPLATE: 'templates/edit',
        TRIGGER_CRON: 'triggers/cron/edit',
        TRIGGER_REST: 'triggers/rest/edit',
        VALIDATION_RULE: 'validations/edit',
        PLUGIN: 'plugins/edit',
        USER: 'permissions/users/edit',
        TRIGGER_MESSAGING: 'triggers/messaging/edit'
      }
    }
  },
  computed: {
    formattedErrorsUsages() {
      return this.regularDeleteErrorsUsages.map((error) => {
        const tempObjKeys = Object.keys(error)

        if (tempObjKeys.length) {
          return { value: `${this.$lang.status[error.resourceType.toUpperCase()]}: ${error.resourceId} - ${error.resourceName}`, item: error }
        }
        else {
          return error
        }
      })
    },
    formattedErrorsResources() {
      return this.regularDeleteErrorsResources.map((error) => {
        const tempObjKeys = Object.keys(error)

        if (tempObjKeys.length) {
          return { value: `${this.$lang.status[error.type.toUpperCase()]}: ${error.id} - ${error.name}`, item: { resourceType: error.type, resourceId: error.id } }
        }
        else {
          return error
        }
      })
    },
    formattedErrorsUsers() {
      return this.regularDeleteErrorsUsers.map((error) => {
        const tempObjKeys = Object.keys(error)

        if (tempObjKeys.length) {
          return { value: `${this.$lang.labels.user}: ${error.id} - ${error.name}`, item: { resourceType: 'USER', resourceId: error.id } }
        }
        else {
          return error
        }
      })
    }
  },
  watch: {
    deleteSuccess (val) {
      if (val) {
        this.showYesNoDialog = true
      }
    },
    triggerForceLogic (val) {
      if (val) {
        this.showYesNoDialog = true
        this.forceDelete = true
      }
    }
  },
  methods: {
    openItem(item) {
      const link = `/${localStorage.selectedLanguage || 'en'}/${this.resourcesTypes[item.resourceType]}/${item.resourceId}`

      window.open(link, '_blank')
    },
    iconHandler(type) {
      switch (type) {
      case 'GLOBAL_TRANSLATION':
        return 'mdi-text'
      case 'MODULE':
        return 'mdi-view-module-outline'
      case 'PLUGIN':
        return 'mdi-puzzle-outline'
      case 'PROCESS':
        return 'mdi-console-network'
      case 'PROCESS_CREDENTIAL':
        return 'mdi-lock'
      case 'SETTING':
        return 'mdi-cog'
      case 'TEXT_TEMPLATE':
        return 'mdi-text-box-outline'
      case 'TRIGGER_CRON':
        return 'mdi-briefcase-clock-outline'
      case 'TRIGGER_MESSAGING':
        return 'mdi-message-text-outline'
      case 'TRIGGER_REST':
        return 'mdi-web'
      case 'VALIDATION_RULE':
        return 'mdi-magnify-scan'
      case 'USER':
        return 'mdi-account'
      default:
        return 'mdi-home'
      }
    },
    closeDialog () {
      this.showYesNoDialog = false
      if (this.deleteSuccess) {
        this.$emit('closeAfterDelete', true)
      } else {
        this.$emit('closeDialog', true)
      }
    },
    submit () {
      this.$emit('submit', this.forceDelete)
    }
  }
}
</script>
<style lang="scss" scoped>
</style>
