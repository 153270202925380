import { getUserNameUsingGET as getUserName } from '@/utils/api'

export const predictBestTimeUnitFromMsValue = async (value) => {
  if (Number(value / 1000 / 60 / 60 / 24).toFixed() === String(value / 1000 / 60 / 60 / 24)) return { value: (value / 1000 / 60 / 60 / 24), unit: 'Day' }
  if (Number(value / 1000 / 60 / 60).toFixed() === String(value / 1000 / 60 / 60)) return { value: (value / 1000 / 60 / 60), unit: 'Hr' }
  if (Number(value / 1000 / 60).toFixed() === String(value / 1000 / 60)) return { value: (value / 1000 / 60), unit: 'Min' }
  if (Number(value / 1000).toFixed() === String(value / 1000)) return { value: (value / 1000), unit: 'Sec' }

  return { value: value, unit: 'MSec' }
}

export const predictBestSizeUnitFromBytesValue = async (value) => {
  if (Number(value / 1024 / 1024).toFixed() === String(value / 1024 / 1024)) return { value: (value / 1024 / 1024), unit: 'Megabytes' }
  if (Number(value / 1024).toFixed() === String(value / 1024)) return { value: (value / 1024), unit: 'Kilobytes' }

  return { value: value, unit: 'Bytes' }
}

export const fetchUserData = async (id) => {
  const data = await getUserName({ id })

  return { id, ...data.data.data }
}

export const copyCreateResource = (item, toScreen, router, type = '', dateTime, isRestore = false, idOnly = false) => {
  const tempItem = item

  if (!idOnly) delete tempItem.id
  delete tempItem.createdOn
  delete tempItem.modifiedOn
  delete tempItem.roles

  tempItem.name = isRestore ? `${item.name} - Restore - ${dateTime}` : `${item.name} - Copy - ${dateTime}`

  router.push({ name: toScreen, params: { copy: tempItem, type: type ? type : '' } })
}
