<template>
  <v-container fluid>
    <div class="pb-4">
      <h1 class="text-h4">{{ $lang.header.credentialDetails }}</h1>
      <v-divider class="my-2"></v-divider>
      <v-row v-if="err || success" dense no-gutters class="px-0">
        <v-col v-if="err" cols="12">
          <v-alert color="error" :data-cy="err">{{ err }}</v-alert>
        </v-col>
        <v-col v-if="success" cols="12">
          <v-alert color="success" :data-cy="success">{{ success }}</v-alert>
        </v-col>
      </v-row>
      <v-row class="fill-height full-width">
        <v-col
          cols="12"
          sm="12"
          md="8"
        >
          <v-alert v-if="!userCanEdit && !loading" dense color="warning" style="color: black">
            {{ $lang.errors.readOnly }}
          </v-alert>
        </v-col>
        <v-col
          cols="12"
          sm="12"
          class="fill-height"
        >
          <v-tabs
            v-model="tab"
          >
            <v-tabs-slider color="accent"></v-tabs-slider>

            <v-tab
              :key="0"
              data-cy="tab-details"
            >
              {{ $lang.labels.details }}
            </v-tab>
            <v-tab
              v-if="userCanEdit && isEdit && queryConsoleFromBe && credential.adminOptions.queryConsole"
              :key="1"
              data-cy="tab-console"
            >
              {{ $lang.labels.console }}
            </v-tab>
            <v-tab
              :key="2"
              data-cy="tab-permissions"
            >
              {{ $lang.labels.permissions }}
            </v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab" class="pb-2 fill-height">
            <v-tab-item
              :key="0"
              class="fill-height"
            >
              <v-card class="pa-2 fill-height">
                <v-form
                  ref="form"
                  v-model="valid"
                  class="pt-1"
                >
                  <v-row wrap no-gutters justify="space-between" class="pb-1">
                    <v-col
                      cols="12"
                      sm="9"
                      class="pr-sm-1 d-flex"
                    >
                      <v-text-field
                        v-model="credential.name"
                        outlined
                        dense
                        :label="$lang.labels.name"
                        required
                        :rules="[v => !!v || 'Required!']"
                        :persistent-hint="isEdit"
                        :hint="formatHint"
                        :readonly="!userCanEdit || credential.isSystem"
                        data-cy="credential-name"
                        class="required-asterisk"
                      ></v-text-field>
                      <v-btn
                        icon
                        color="primary"
                        class="ml-2"
                        style="margin-top: 2px"
                        title="copy"
                        @click="copyField()"
                      >
                        <v-icon>mdi-clipboard-outline</v-icon>
                      </v-btn>
                    </v-col>
                    <v-col v-if="isEdit" cols="3" class="pl-1 text-right">
                      <v-btn
                        class="button-default-width"
                        color="primary"
                        :disabled="!userCanEdit"
                        @click="changeStatus()"
                      >
                        {{ credential.status === 'ACTIVE' ? $lang.actions.deactivate : $lang.actions.activate }}
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-row v-if="!loading && credential.encryptedFields" wrap no-gutters>
                    <v-col cols="12" class="pb-2">
                      <h4 class="text-h5">{{ $lang.header.values }} - {{ $lang.status[credential.type] }}</h4>
                    </v-col>
                    <values-component
                      :can-edit="userCanEdit"
                      :is-edit="isEdit"
                      :is-copy="isCopy"
                      :type="credential.type"
                      :default-values="credential.values"
                      :encryption-fields="credential.encryptedFields"
                      :loading="loading"
                      @setValues="credential.values = $event"
                      @setValid="valuesValid = $event"
                      @setEncryptedFields="credential.encryptedFields = $event"
                    ></values-component>
                    <v-col
                      cols="12"
                      class="py-2"
                    >
                      <hr style="border-bottom-color: var(--v-primary-base)">
                    </v-col>
                    <v-col
                      cols="12"
                      class="pb-2"
                    >
                      <v-row wrap no-gutters align="center">
                        <v-checkbox
                          v-model="credential.isGdprRelevant"
                          :label="$lang.labels.gdpr"
                          hide-details
                          class="mt-0"
                        ></v-checkbox>
                        <v-checkbox
                          v-if="$route.params.type ? $route.params.type.toUpperCase() === 'JDBC' : credential && credential.type && credential.type.toUpperCase() === 'JDBC'"
                          v-model="credential.adminOptions.queryConsole"
                          hide-details
                          class="align-center align-self-center ml-3 mt-0"
                          :label="$lang.labels.allowQueryConsole"
                          :readonly="!userCanEdit || credential.isSystem"
                        ></v-checkbox>
                        <span
                          v-if="credential.adminOptions.queryConsole && !queryConsoleFromBe"
                          class="ml-1 color-primary"
                        >( {{ $lang.hints.saveFirst }} )</span>
                      </v-row>
                    </v-col>
                  </v-row>
                  <v-row v-if="testResult" dense no-gutters class="px-0">
                    <v-col v-if="testResult" cols="12">
                      <v-alert :color="resultColor">{{ testResult }}</v-alert>
                    </v-col>
                  </v-row>
                  <v-row no-gutters wrap justify="space-between" class="pb-4">
                    <v-col
                      cols="6"
                      class="text-left"
                    >
                      <div class="d-inline-flex align-center">
                        <v-btn
                          class="button-default-width"
                          color="secondary"
                          :to="{ name: 'credentials', params: { lang: $lang.current_lang } }"
                        >
                          <v-icon
                            right
                            dark
                            class="ma-0"
                          >
                            mdi-arrow-left
                          </v-icon>
                          <span class="ml-1">{{ $lang.actions.back }}</span>
                        </v-btn>
                        <action-button-with-confirmation
                          v-if="isEdit"
                          :action-text="$lang.actions.areYouSureYouWantToDelete"
                          :action-text-suffix="credential.name"
                          :title="$lang.actions.delete"
                          type="credential"
                          class="ml-2"
                          :is-disabled="!userCanDelete"
                          :button-text="$lang.actions.delete"
                          :button-color="'error'"
                          :data-cy="'credential-delete'"
                          :forced-option="true"
                          :trigger-force-logic="triggerForceLogic"
                          :regular-delete-errors-usages="regularDeleteErrorsUsages"
                          :delete-success="deleteSuccess"
                          @closeAfterDelete="$router.push({ name: 'credentials' })"
                          @submit="deleteCredentialFunct($event)"
                          @closeDialog="''"
                        />
                      </div>
                    </v-col>
                    <v-col
                      cols="6"
                      class="text-right"
                    >
                      <v-progress-circular
                        v-if="lock || loading"
                        indeterminate
                        :color="loading ? 'success' : 'accent'"
                      ></v-progress-circular>
                      <v-btn
                        :disabled="!valid || !valuesValid || !credential.values || lock || !userCanEdit || ['REST', 'PLUGIN', 'SECURITY', 'SCRIPT'].includes(credential.type)"
                        color="accent"
                        class="button-default-width ml-1 color-accent-text"
                        @click="test()"
                      >
                        {{ $lang.actions.test }}
                      </v-btn>
                      <v-btn
                        :disabled="!valid || !valuesValid || !credential.values || lock || !userCanEdit"
                        color="success"
                        class="button-default-width ml-2"
                        @click="submit()"
                      >
                        {{ $lang.actions.submit }}
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-form>
              </v-card>
            </v-tab-item>
            <v-tab-item
              v-if="userCanEdit && isEdit && queryConsoleFromBe && credential.adminOptions.queryConsole"
              :key="1"
              class="fill-height"
            >
              <v-card v-if="!loading" class="pa-2 fill-height">
                <v-row wrap no-gutters class="pb-4">
                  <v-col cols="12" class="pb-2">
                    <div style="width: 100%; height: 100%">
                      <editor
                        :title="$lang.labels.queryConsole"
                        :item="script"
                        @fromGlobalEditor="script = $event"
                      ></editor>
                    </div>
                  </v-col>
                  <v-col cols="12" class="pb-2 px-2 text-right">
                    <v-progress-circular
                      v-if="executingQuery"
                      indeterminate
                      color="accent"
                    ></v-progress-circular>
                    <v-btn
                      :disabled="!script || executingQuery"
                      color="accent"
                      class="button-default-width ml-1 color-accent-text"
                      @click="executeJdbcScriptFunc()"
                    >
                      {{ $lang.actions.execute }}
                    </v-btn>
                  </v-col>
                </v-row>
                <v-divider class="py-1"></v-divider>
                <v-row v-if="showExecuteSuccessResult" wrap no-gutters class="pb-4">
                  <v-col cols="12" class="pb-2">
                    <v-card class="pa-2" flat>
                      <v-card-title class="pb-2 d-flex flex-grow-1 align-center">
                        <div>{{ $lang.header.result }}</div>
                        <v-spacer></v-spacer>
                        <div class="d-flex">
                          <v-card-title>{{ $lang.labels.affectedRows }}: <span
                            class="color-primary ml-1"
                          >{{ executeSuccessResult.affectedRows }}</span></v-card-title>
                          <v-card-title class="ml-2">{{ $lang.labels.success }}: <span class="color-primary ml-1">{{
                            executeSuccessResult.isSuccess ? $lang.labels.yes : $lang.labels.no
                          }}</span></v-card-title>
                        </div>
                      </v-card-title>
                      <v-divider class="py-1"></v-divider>
                      <v-card-subtitle class="pb-2">{{ $lang.labels.resultDetails }}:</v-card-subtitle>
                      <div v-if="executeSuccessResult.isSuccess">
                        <v-data-table
                          :headers="dynamicTableHead"
                          :items="executeSuccessResult.result"
                          class="elevation-0"
                          :footer-props="{
                            'items-per-page-options': rowsPerPageItemsGlobal
                          }"
                        />
                      </div>
                      <div v-else>
                        <v-row wrap no-gutters class="pt-2">
                          <v-col cols="12">
                            <v-alert color="error">{{ executeSuccessResult.error }}</v-alert>
                          </v-col>
                        </v-row>
                      </div>
                    </v-card>
                  </v-col>
                  <v-col cols="12" class="pb-2 px-2 text-right">
                    <v-btn
                      color="accent"
                      class="button-default-width ml-1 color-accent-text"
                      @click="resetExecuteJdbcScript()"
                    >
                      {{ $lang.actions.reset }}
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card>
            </v-tab-item>
            <v-tab-item
              :key="2"
              class="fill-height"
            >
              <v-card class="pa-2 fill-height">
                <v-row wrap no-gutters class="pb-4">
                  <v-col cols="12" class="pb-2">
                    <div v-if="allRoles && allRoles.length > 0" style="width: 100%; height: 100%">
                      <h3 class="pb-1">{{ $lang.status.EDIT }}</h3>
                      <user-roles-select
                        :role="editRolesIds"
                        :options="allRoles"
                        data-cy="roles-edit"
                        :required="false"
                        :readonly="!userCanEdit"
                        @changeRoles="editRolesIds = $event"
                      ></user-roles-select>
                      <h3 class="pb-1">{{ $lang.status.USE }}</h3>
                      <user-roles-select
                        :role="useRolesIds"
                        :options="useRolePool"
                        data-cy="roles-use"
                        :required="false"
                        :readonly="!userCanEdit"
                        @changeRoles="useRolesIds = $event"
                      ></user-roles-select>
                      <h3 class="pb-1">{{ $lang.status.VIEW }}</h3>
                      <user-roles-select
                        :role="viewRolesIds"
                        :options="viewRolePool"
                        data-cy="roles-view"
                        :required="false"
                        :readonly="!userCanEdit"
                        @changeRoles="viewRolesIds = $event"
                      ></user-roles-select>
                    </div>
                  </v-col>
                </v-row>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </v-col>
      </v-row>
      <v-snackbar
        v-model="snackShow"
        :color="snackColor"
        content-class="text-center"
        top
      >
        <span class="color-accent-text">{{ snackbarText }}</span>
      </v-snackbar>
    </div>
  </v-container>
</template>

<script>
import ValuesComponent from './Values'
import {
  disableProcessCredentialUsingGET as disableCredential,
  enableProcessCredentialUsingGET as enableCredential,
  getProcessCredentialByIdUsingGET as getCredential,
  updateTwilioProcessCredentialUsingPUT as updateTwilio,
  createTwilioProcessCredentialUsingPOST as createTwilio,
  updateSmtpProcessCredentialUsingPUT as updateSmtp,
  createSmtpProcessCredentialUsingPOST as createSmtp,
  updateJdbcProcessCredentialUsingPUT as updateJdbc,
  createJdbcProcessCredentialUsingPOST as createJdbc,
  updateMongodbProcessCredentialUsingPUT as updateMongodb,
  createMongodbProcessCredentialUsingPOST as createMongodb,
  updateJwtProcessCredentialUsingPUT as updateJwt,
  createJwtProcessCredentialUsingPOST as createJwt,
  updateSlackProcessCredentialUsingPUT as updateSlack,
  createSlackProcessCredentialUsingPOST as createSlack,
  createRestCredentialUsingPOST as createRest,
  updateRestCredentialUsingPUT as updateRest,
  createPluginCredentialUsingPOST as createPlugin,
  updatePluginCredentialUsingPUT as updatePlugin,
  createFlowyCredentialUsingPOST as createFlowy,
  updateFlowyCredentialUsingPUT as updateFlowy,
  updateAwsCredentialUsingPUT as updateAws,
  createAwsCredentialUsingPOST as createAws,
  updateSecurityCredentialUsingPUT as updateSecurity,
  createSecurityCredentialUsingPOST as createSecurity,
  getRolesUsingGET as getRoles,
  testJwtProcessCredentialUsingPOST as testJwt,
  testTwilioProcessCredentialUsingPOST as testTwilio,
  testSmtpProcessCredentialUsingPOST as testSmtp,
  testJdbcProcessCredentialUsingPOST as testJdbc,
  testMongodbProcessCredentialUsingPOST as testMongoDb,
  testSlackProcessCredentialUsingPOST as testSlack,
  testFlowyCredentialUsingPOST as testFlowy,
  testAwsCredentialUsingPOST as testAws,
  executeJdbcScriptUsingPOST as executeJdbcScript,
  createScriptCredentialUsingPOST as createScript,
  updateScriptCredentialUsingPUT as updateScript,
  createImapProcessCredentialUsingPOST as createImap,
  updateImapProcessCredentialUsingPUT as updateImap,
  testImapProcessCredentialUsingPOST as testImap,
  createKafkaCredentialUsingPOST as createKafka,
  updateKafkaCredentialUsingPUT as updateKafka,
  testKafkaCredentialUsingPOST as testKafka,
  createPaymentSensePacCredentialUsingPOST as createPaymentSensePac,
  updatePaymentSensePacCredentialUsingPUT as updatePaymentSensePac,
  testPaymentSensePacCredentialUsingPOST as testPaymentSensePac,
  createJmsCredentialUsingPOST as createJms,
  updateJmsCredentialUsingPUT as updateJms,
  testJmsCredentialUsingPOST as testJms,
  createPaymentSenseConnectECredentialUsingPOST as createPaymentSenseConnectE,
  updatePaymentSenseConnectECredentialUsingPUT as updatePaymentSenseConnectE,
  testPaymentSenseConnectECredentialUsingPOST as testPaymentSenseConnectE,
  deleteProcessCredentialUsingDELETE as deleteCredential,
  createRabbitmqCredentialUsingPOST as createRabbitmq,
  updateRabbitmqCredentialUsingPUT as updateRabbitmq,
  testRabbitmqCredentialUsingPOST as testRabbitmq
} from '@/utils/api'
import editor from '../../components/ui/GlobalEditor'
import { mapState } from 'vuex'
import UserRolesSelect from '../../components/ui/UserRolesSelect'
import copy from 'copy-to-clipboard'
import ActionButtonWithConfirmation from '@/components/ui/ActionButtonWithConfirmation.vue'

export default {
  components: {
    ActionButtonWithConfirmation,
    ValuesComponent,
    editor,
    UserRolesSelect
  },
  data() {
    return {
      snackbarText: '',
      snackShow: false,
      snackColor: 'success',
      err: '',
      success: '',
      testResult: '',
      resultColor: '',
      tab: 0,
      valid: false,
      valuesValid: false,
      isEdit: false,
      loading: true,
      lock: false,
      credential: {
        createdOn: '',
        id: 0,
        modifiedOn: '',
        name: '',
        status: '',
        type: this.$route.params.type ? this.$route.params.type.toUpperCase() : '',
        values: null,
        isGdprRelevant: false,
        isSystem: false,
        encryptedFields: null,
        adminOptions: {
          queryConsole: false
        }
      },
      script: '',
      allRoles: [],
      editRolesIds: [],
      useRolesIds: [],
      viewRolesIds: [],
      permissionsTypes: ['EDIT', 'USE', 'VIEW'],
      userRolesIds: [],
      userCanEdit: false,
      isSuperUser: false,
      executingQuery: false,
      executeResult: '',
      executeColor: '',
      showExecuteSuccessResult: false,
      executeSuccessResult: {
        affectedRows: 0,
        isSuccess: false,
        result: [
          {
            'additionalProp1': {},
            'additionalProp2': {},
            'additionalProp3': {}
          }
        ],
        error: ''
      },
      queryConsoleFromBe: false,
      dynamicTableHead: [],
      userCanDelete: false,
      triggerForceLogic: false,
      regularDeleteErrorsUsages: [],
      deleteSuccess: false,
      isCopy: false
    }
  },
  computed: {
    ...mapState('app', ['rowsPerPageItemsGlobal', 'userSettings']),
    formatHint() {
      return `${this.$lang.labels.createdOn}: ${this.$options.filters.formatDateTime(this.credential.createdOn)}, ${this.$lang.labels.modifiedOn}: ${this.$options.filters.formatDateTime(this.credential.modifiedOn)}, ID: ${this.credential.id}`
    },
    useRolePool() {
      return this.allRoles.filter((x) => !this.editRolesIds.includes(x.id))
    },
    viewRolePool() {
      return this.allRoles.filter((x) => !this.editRolesIds.includes(x.id) && !this.useRolesIds.includes(x.id))
    }
  },
  created() {
    this.isEdit = this.$route.name === 'credentialEdit'
    this.isCopy = !!this.$route.params.copy

    let user = null

    if (localStorage.userData) {
      user = JSON.parse(localStorage.userData)

      this.userRolesIds = user.roles.map((x) => x.id)
      this.isSuperUser = !!user.roles.find((x) => x.name === 'SUPER_USER')
      this.userCanDelete = !!user.roles.find((x) => x.name === 'CREDENTIAL_DELETER') || this.isSuperUser
    }

    this.init()
      .then(() => {
        if (this.isEdit && this.$route.params.id) {
          this.loading = true
          getCredential({ id: this.$route.params.id })
            .then((res) => {
              this.credential = res.data.data

              this.editRolesIds = this.credential.roles.filter((x) => x.permissionType === 'EDIT').map((y) => y.role.id)
              this.useRolesIds = this.credential.roles.filter((x) => x.permissionType === 'USE').map((y) => y.role.id)
              this.viewRolesIds = this.credential.roles.filter((x) => x.permissionType === 'VIEW').map((y) => y.role.id)

              this.userCanEdit = this.isSuperUser ? true : !!this.editRolesIds.find((x) => this.userRolesIds.includes(x))

              if (!this.credential.adminOptions) this.credential.adminOptions = { queryConsole: false }

              if (res.data.data && res.data.data.adminOptions && res.data.data.adminOptions.queryConsole) {
                this.queryConsoleFromBe = true
              }

              if (this.credential.encryptedFields && this.credential.encryptedFields.length > 0) {
                this.credential.encryptedFields.map((x) => {
                  x.isChanged = false
                  x.isEncrypt = x.type !== 'PLAIN'

                  return x
                })
                if (!this.credential.values) this.credential.values = {}
                let valuesKeys = []

                if (this.credential.values) valuesKeys = Object.keys(this.credential.values)
                this.credential.encryptedFields.forEach((x) => {
                  if (!valuesKeys.includes(x.field)) {
                    this.credential.values[x.field] = ''
                  }
                })
              }

              this.formatEncryptedFields()

              this.loading = false
            })
            .catch((err) => {
              this.err = err
            })
        } else {
          this.loading = true
          this.editRolesIds = user.roles.filter((x) => !x.isSystem).map((x) => x.id)
          this.userCanEdit = true
          if (this.$route.params.copy) {
            this.credential = this.$route.params.copy

            if (!this.credential.adminOptions) this.credential.adminOptions = { queryConsole: false }

            if (this.credential.adminOptions && this.credential.adminOptions.queryConsole) {
              this.queryConsoleFromBe = true
            }

            if (this.credential.encryptedFields && this.credential.encryptedFields.length > 0) {
              this.credential.encryptedFields.map((x) => {
                x.isChanged = false
                x.isEncrypt = x.type !== 'PLAIN'

                return x
              })
              if (!this.credential.values) this.credential.values = {}
              let valuesKeys = []

              if (this.credential.values) valuesKeys = Object.keys(this.credential.values)
              this.credential.encryptedFields.forEach((x) => {
                if (!valuesKeys.includes(x.field)) {
                  this.credential.values[x.field] = ''
                }
              })
            }

            this.formatEncryptedFields()

            this.loading = false
          } else {
            this.formatEncryptedFields()
            this.loading = false
          }
        }
      })
  },
  methods: {
    deleteCredentialFunct(isForced = false) {
      this.triggerForceLogic = false
      this.regularDeleteErrorsUsages = []
      this.deleteSuccess = false
      deleteCredential({ id: this.credential.id, force: isForced })
        .then((res) => {
          if (res.status === 200) {
            this.deleteSuccess = true
            this.regularDeleteErrorsUsages = res.data.data.usages
          } else {
            this.triggerForceLogic = true
            this.regularDeleteErrorsUsages = res.response.data.data.usages
          }
        })
        .catch((err) => {
          this.err = err
        })
    },
    copyField() {
      if (this.credential.name) {
        copy(this.credential.name)
        this.showSnack(this.$lang.success.copiedClipboard, 'success')
      } else {
        this.showSnack(this.$lang.errors.nothingToCopy, 'warning')
      }
    },
    showSnack(text, color = 'success') {
      this.snackbarText = text
      this.snackColor = color
      this.snackShow = true
    },
    resetExecuteJdbcScript() {
      this.executeResult = ''
      this.executeColor = ''
      this.executeSuccessResult = {
        affectedRows: 0,
        isSuccess: false,
        result: [],
        error: ''
      }
      this.showExecuteSuccessResult = false
    },
    async executeJdbcScriptFunc() {
      this.executeResult = ''
      this.executeColor = ''
      this.dynamicTableHead = []
      this.executingQuery = true

      try {

        const res = await executeJdbcScript({ id: this.credential.id, request: { script: this.script } })

        if (res && res.status !== 200) {

          this.executeColor = 'error'
          this.executeResult = this.$lang.errors.execute

          setTimeout(() => this.executeResult = '', 5000)
          this.executingQuery = false

          return
        }

        if (res && res.data && res.data.data && !res.data.data.isSuccess) {
          this.executeColor = 'error'
          this.executeResult = res ? res.data.data.error : this.$lang.errors.execute
          setTimeout(() => this.executeResult = '', res && res.data.data.error ? 20000 : 5000)
        }
        if (res && res.data && res.data.data) {
          this.executeSuccessResult = res.data.data
          if (res && res.data && res.data.data && res.data.data.isSuccess) {
            const tableHeaderKeys = Object.keys(res.data.data.result[0])

            tableHeaderKeys.forEach((x) => {
              this.dynamicTableHead.push({ text: x, value: x,sortable: false
              })
            })
          }
          this.showExecuteSuccessResult = true
        }
        this.executingQuery = false

      } catch (err) {
        this.executeColor = 'error'
        this.executeResult = err
        this.executingQuery = false
        setTimeout(() => this.executeResult = '', 5000)
      }
    },
    formatEncryptedFields() {
      const typeFields = {
        JDBC: ['url', 'username', 'password'],
        JWT: ['privateKey'],
        MONGODB: ['url', 'clientKey'],
        SMTP: ['username', 'password', 'host', 'port', 'senderAddress'],
        TWILIO: ['sid', 'token'],
        SLACK: ['channelId', 'token'],
        FLOWY: ['username', 'apiKey'],
        REST: [],
        PLUGIN: [],
        AWS: ['accessKeyId', 'region', 's3Bucket', 'secretKey'],
        SECURITY: ['key', 'passphrase'],
        SCRIPT: [],
        IMAP: ['host', 'password', 'port', 'username'],
        KAFKA: ['bootstrapServers', 'groupId', 'topic'],
        PAYMENT_SENSE_PAC: ['apiKey', 'hostUrl', 'installerId', 'softwareHouseId', 'username'],
        JMS: ['password', 'destinationName', 'destinationType', 'uri', 'username'],
        PAYMENT_SENSE_CONNECT_E: ['hostUrl', 'jwt', 'merchantUrl'],
        RABBITMQ: ['exchange', 'host', 'password', 'port', 'routingKey', 'virtualHost', 'username']
      }

      if (this.credential.encryptedFields && this.credential.encryptedFields.length > 0) {
        const existingFields = this.credential.encryptedFields.map((x) => x.field)

        if (this.credential.type === 'JWT') {
          existingFields.forEach((field, index) => {
            const splitField = field.split('.')

            if (splitField.length > 1) {
              existingFields[index] = splitField[splitField.length - 1]
              this.credential.encryptedFields[index].field = splitField[splitField.length - 1]
            }
          })
          if (!existingFields.includes('privateKey')) {
            this.credential.encryptedFields.push({
              field: 'privateKey',
              type: 'PLAIN',
              isEncrypt: false,
              isChanged: false
            })
          }
        } else {
          typeFields[this.credential.type].forEach((field) => {
            if (!existingFields.includes(field)) {
              this.credential.encryptedFields.push({
                field: field,
                type: 'PLAIN',
                isEncrypt: false,
                isChanged: false
              })
            }
          })
        }
      } else {
        switch (this.credential.type) {
        case 'RABBITMQ':
          this.credential.encryptedFields = [
            {
              field: 'exchange',
              type: 'PLAIN',
              isEncrypt: false,
              isChanged: false
            },
            {
              field: 'host',
              type: 'PLAIN',
              isEncrypt: false,
              isChanged: false
            },
            {
              field: 'password',
              type: 'PLAIN',
              isEncrypt: false,
              isChanged: false
            },
            {
              field: 'port',
              type: 'PLAIN',
              isEncrypt: false,
              isChanged: false
            },
            {
              field: 'routingKey',
              type: 'PLAIN',
              isEncrypt: false,
              isChanged: false
            },
            {
              field: 'virtualHost',
              type: 'PLAIN',
              isEncrypt: false,
              isChanged: false
            },
            {
              field: 'username',
              type: 'PLAIN',
              isEncrypt: false,
              isChanged: false
            }
          ]
          break
        case 'PAYMENT_SENSE_CONNECT_E':
          this.credential.encryptedFields = [
            {
              field: 'hostUrl',
              type: 'PLAIN',
              isEncrypt: false,
              isChanged: false
            },
            {
              field: 'jwt',
              type: 'PLAIN',
              isEncrypt: false,
              isChanged: false
            },
            {
              field: 'merchantUrl',
              type: 'PLAIN',
              isEncrypt: false,
              isChanged: false
            }
          ]
          break
        case 'JMS':
          this.credential.encryptedFields = [
            {
              field: 'uri',
              type: 'PLAIN',
              isEncrypt: false,
              isChanged: false
            },
            {
              field: 'username',
              type: 'PLAIN',
              isEncrypt: false,
              isChanged: false
            },
            {
              field: 'password',
              type: 'PLAIN',
              isEncrypt: false,
              isChanged: false
            },
            {
              field: 'destinationName',
              type: 'PLAIN',
              isEncrypt: false,
              isChanged: false
            },
            {
              field: 'destinationType',
              type: 'PLAIN',
              isEncrypt: false,
              isChanged: false
            }
          ]
          break
        case 'PAYMENT_SENSE_PAC':
          this.credential.encryptedFields = [
            {
              field: 'apiKey',
              type: 'PLAIN',
              isEncrypt: false,
              isChanged: false
            },
            {
              field: 'hostUrl',
              type: 'PLAIN',
              isEncrypt: false,
              isChanged: false
            },
            {
              field: 'installerId',
              type: 'PLAIN',
              isEncrypt: false,
              isChanged: false
            },
            {
              field: 'softwareHouseId',
              type: 'PLAIN',
              isEncrypt: false,
              isChanged: false
            },
            {
              field: 'username',
              type: 'PLAIN',
              isEncrypt: false,
              isChanged: false
            }
          ]
          break
        case 'KAFKA':
          this.credential.encryptedFields = [
            {
              field: 'bootstrapServers',
              type: 'PLAIN',
              isEncrypt: false,
              isChanged: false
            },
            {
              field: 'groupId',
              type: 'PLAIN',
              isEncrypt: false,
              isChanged: false
            },
            {
              field: 'topic',
              type: 'PLAIN',
              isEncrypt: false,
              isChanged: false
            }
          ]
          break
        case 'SECURITY':
          this.credential.encryptedFields = [
            {
              field: 'key',
              type: 'PLAIN',
              isEncrypt: false,
              isChanged: false
            },
            {
              field: 'passphrase',
              type: 'PLAIN',
              isEncrypt: false,
              isChanged: false
            }
          ]
          break
        case 'JWT':
          this.credential.encryptedFields = [
            {
              field: 'privateKey',
              type: 'PLAIN',
              isEncrypt: false,
              isChanged: false
            }
          ]
          break
        case 'MONGODB':
          this.credential.encryptedFields = [
            {
              field: 'url',
              type: 'PLAIN',
              isEncrypt: false,
              isChanged: false
            },
            {
              field: 'clientKey',
              type: 'PLAIN',
              isEncrypt: false,
              isChanged: false
            }
          ]
          break
        case 'JDBC':
          this.credential.encryptedFields = [
            {
              field: 'url',
              type: 'PLAIN',
              isEncrypt: false,
              isChanged: false
            },
            {
              field: 'username',
              type: 'PLAIN',
              isEncrypt: false,
              isChanged: false
            },
            {
              field: 'password',
              type: 'PLAIN',
              isEncrypt: false,
              isChanged: false
            }
          ]
          break
        case 'SMTP':
          this.credential.encryptedFields = [
            {
              field: 'username',
              type: 'PLAIN',
              isEncrypt: false,
              isChanged: false
            },
            {
              field: 'password',
              type: 'PLAIN',
              isEncrypt: false,
              isChanged: false
            },
            {
              field: 'host',
              type: 'PLAIN',
              isEncrypt: false,
              isChanged: false
            },
            {
              field: 'port',
              type: 'PLAIN',
              isEncrypt: false,
              isChanged: false
            },
            {
              field: 'senderAddress',
              type: 'PLAIN',
              isEncrypt: false,
              isChanged: false
            }
          ]
          break
        case 'TWILIO':
          this.credential.encryptedFields = [
            {
              field: 'sid',
              type: 'PLAIN',
              isEncrypt: false,
              isChanged: false
            },
            {
              field: 'token',
              type: 'PLAIN',
              isEncrypt: false,
              isChanged: false
            }
          ]
          break
        case 'SLACK':
          this.credential.encryptedFields = [
            {
              field: 'channelId',
              type: 'PLAIN',
              isEncrypt: false,
              isChanged: false
            },
            {
              field: 'token',
              type: 'PLAIN',
              isEncrypt: false,
              isChanged: false
            }
          ]
          break
        case 'FLOWY':
          this.credential.encryptedFields = [
            {
              field: 'username',
              type: 'PLAIN',
              isEncrypt: false,
              isChanged: false
            },
            {
              field: 'apiKey',
              type: 'PLAIN',
              isEncrypt: false,
              isChanged: false
            }
          ]
          break
        case 'REST':
          this.credential.encryptedFields = []
          break
        case 'PLUGIN':
          this.credential.encryptedFields = []
          break
        case 'AWS':
          this.credential.encryptedFields = [
            {
              field: 'accessKeyId',
              type: 'PLAIN',
              isEncrypt: false,
              isChanged: false
            },
            {
              field: 'region',
              type: 'PLAIN',
              isEncrypt: false,
              isChanged: false
            },
            {
              field: 's3Bucket',
              type: 'PLAIN',
              isEncrypt: false,
              isChanged: false
            },
            {
              field: 'secretKey',
              type: 'PLAIN',
              isEncrypt: false,
              isChanged: false
            }
          ]
          break
        case 'SCRIPT':
          this.credential.encryptedFields = []
          break
        case 'IMAP':
          this.credential.encryptedFields = [
            {
              field: 'host',
              type: 'PLAIN',
              isEncrypt: false,
              isChanged: false
            },
            {
              field: 'password',
              type: 'PLAIN',
              isEncrypt: false,
              isChanged: false
            },
            {
              field: 'port',
              type: 'PLAIN',
              isEncrypt: false,
              isChanged: false
            },
            {
              field: 'username',
              type: 'PLAIN',
              isEncrypt: false,
              isChanged: false
            }
          ]
          break
        default:
        }
      }
    },
    async test() {
      this.testResult = ''
      this.resultColor = ''
      this.lock = true

      let res = null

      const values = { values: this.credential.values, encryptedFields: this.credential.encryptedFields }

      if (!this.isEdit) {
        values.encryptedFields = values.encryptedFields.map((x) => {
          x.type = 'PLAIN'

          return x
        })
      }

      try {
        switch (this.credential.type) {
        case 'RABBITMQ':
          res = await testRabbitmq({ values })
          break
        case 'PAYMENT_SENSE_CONNECT_E':
          res = await testPaymentSenseConnectE({ values })
          break
        case 'JMS':
          res = await testJms({ values })
          break
        case 'PAYMENT_SENSE_PAC':
          res = await testPaymentSensePac({ values })
          break
        case 'KAFKA':
          res = await testKafka({ values })
          break
        case 'JWT':
          res = await testJwt({ values })
          break
        case 'MONGODB':
          res = await testMongoDb({ values })
          break
        case 'JDBC':
          res = await testJdbc({ values })
          break
        case 'SMTP':
          res = await testSmtp({ values })
          break
        case 'TWILIO':
          res = await testTwilio({ values })
          break
        case 'SLACK':
          res = await testSlack({ values })
          break
        case 'FLOWY':
          res = await testFlowy({ values })
          break
        case 'AWS':
          res = await testAws({ values })
          break
        case 'IMAP':
          res = await testImap({ values })
          break
        default:
          res = null
        }

        if (res && res.status !== 200) {

          this.resultColor = 'error'
          this.testResult = this.$lang.errors.test

          setTimeout(() => this.testResult = '', 5000)
          this.lock = false

          return
        }

        if (res && res.data.data.isValid) {
          this.resultColor = 'success'
          this.testResult = this.$lang.success.test
          setTimeout(() => this.testResult = '', 5000)
        } else {
          this.resultColor = 'error'
          this.testResult = res ? res.data.data.error : this.$lang.errors.test
          setTimeout(() => this.testResult = '', res && res.data.data.error ? 20000 : 5000)
        }
        this.lock = false

      } catch (err) {
        this.resultColor = 'error'
        this.testResult = err
        this.lock = false
        setTimeout(() => this.testResult = '', 5000)
      }
    },
    init() {
      return new Promise((resolve) => {
        getRoles()
          .then((res) => {
            this.allRoles = res.data.data.items
            resolve()
          })
          .catch((error) => {
            console.log(error)
            resolve()
          })
      })
    },
    afterSave(savedData) {
      this.credential = savedData

      this.editRolesIds = this.credential.roles.filter((x) => x.permissionType === 'EDIT').map((y) => y.role.id)
      this.useRolesIds = this.credential.roles.filter((x) => x.permissionType === 'USE').map((y) => y.role.id)
      this.viewRolesIds = this.credential.roles.filter((x) => x.permissionType === 'VIEW').map((y) => y.role.id)

      this.userCanEdit = this.isSuperUser ? true : !!this.editRolesIds.find((x) => this.userRolesIds.includes(x))

      if (!this.credential.adminOptions) this.credential.adminOptions = { queryConsole: false }

      if (savedData && savedData.adminOptions && savedData.adminOptions.queryConsole) {
        this.queryConsoleFromBe = true
      }

      if (this.credential.encryptedFields && this.credential.encryptedFields.length > 0) {
        this.credential.encryptedFields.map((x) => {
          x.isChanged = false
          x.isEncrypt = x.type !== 'PLAIN'

          return x
        })
        if (!this.credential.values) this.credential.values = {}
        let valuesKeys = []

        if (this.credential.values) valuesKeys = Object.keys(this.credential.values)
        this.credential.encryptedFields.forEach((x) => {
          if (!valuesKeys.includes(x.field)) {
            this.credential.values[x.field] = ''
          }
        })
      }

      this.formatEncryptedFields()

      this.loading = false
    },
    async submit() {
      this.loading = true
      this.lock = true

      let res = null

      const localCredential = JSON.parse(JSON.stringify(this.credential))

      const { id } = localCredential

      if (!this.isEdit) {
        delete localCredential.id
        localCredential.status = 'ACTIVE'
      }
      delete localCredential.createdOn
      delete localCredential.modifiedOn

      const tempRoles = []

      this.editRolesIds.forEach((x) => {
        tempRoles.push({ permissionType: 'EDIT', roleId: x })
      })

      this.useRolesIds.forEach((x) => {
        tempRoles.push({ permissionType: 'USE', roleId: x })
      })

      this.viewRolesIds.forEach((x) => {
        tempRoles.push({ permissionType: 'VIEW', roleId: x })
      })

      localCredential.roles = tempRoles

      localCredential.encryptedFields.map((x) => {
        if (x.type !== 'PLAIN') {
          x.type = x.isChanged ? 'ENCRYPT' : 'ENCRYPTED'
        }

        if (!localCredential.values[x.field] && localCredential.type === 'JWT' && x.field !== 'privateKey') {
          x.field = `payload.${x.field}`
        }

        delete x.isChanged
        delete x.isEncrypt

        return x
      })

      try {
        switch (localCredential.type) {
        case 'RABBITMQ':
          res = this.isEdit ? await updateRabbitmq({
            id,
            request: localCredential
          }) : await createRabbitmq({ request: localCredential })
          break
        case 'PAYMENT_SENSE_CONNECT_E':
          res = this.isEdit ? await updatePaymentSenseConnectE({
            id,
            request: localCredential
          }) : await createPaymentSenseConnectE({ request: localCredential })
          break
        case 'JMS':
          res = this.isEdit ? await updateJms({
            id,
            request: localCredential
          }) : await createJms({ request: localCredential })
          break
        case 'PAYMENT_SENSE_PAC':
          res = this.isEdit ? await updatePaymentSensePac({
            id,
            request: localCredential
          }) : await createPaymentSensePac({ request: localCredential })
          break
        case 'KAFKA':
          res = this.isEdit ? await updateKafka({
            id,
            request: localCredential
          }) : await createKafka({ request: localCredential })
          break
        case 'SECURITY':
          res = this.isEdit ? await updateSecurity({
            id,
            request: localCredential
          }) : await createSecurity({ request: localCredential })
          break
        case 'JWT':
          res = this.isEdit ? await updateJwt({
            id,
            request: localCredential
          }) : await createJwt({ request: localCredential })
          break
        case 'MONGODB':
          res = this.isEdit ? await updateMongodb({
            id,
            request: localCredential
          }) : await createMongodb({ request: localCredential })
          break
        case 'JDBC':
          res = this.isEdit ? await updateJdbc({
            id,
            request: localCredential
          }) : await createJdbc({ request: localCredential })
          break
        case 'SMTP':
          res = this.isEdit ? await updateSmtp({
            id,
            request: localCredential
          }) : await createSmtp({ request: localCredential })
          break
        case 'TWILIO':
          res = this.isEdit ? await updateTwilio({
            id,
            request: localCredential
          }) : await createTwilio({ request: localCredential })
          break
        case 'SLACK':
          res = this.isEdit ? await updateSlack({
            id,
            request: localCredential
          }) : await createSlack({ request: localCredential })
          break
        case 'REST':
          res = this.isEdit ? await updateRest({
            id,
            request: localCredential
          }) : await createRest({ request: localCredential })
          break
        case 'FLOWY':
          res = this.isEdit ? await updateFlowy({
            id,
            request: localCredential
          }) : await createFlowy({ request: localCredential })
          break
        case 'PLUGIN':
          res = this.isEdit ? await updatePlugin({
            id,
            request: localCredential
          }) : await createPlugin({ request: localCredential })
          break
        case 'AWS':
          res = this.isEdit ? await updateAws({
            id,
            request: localCredential
          }) : await createAws({ request: localCredential })
          break
        case 'SCRIPT':
          res = this.isEdit ? await updateScript({
            id,
            request: localCredential
          }) : await createScript({ request: localCredential })
          break
        case 'IMAP':
          res = this.isEdit ? await updateImap({
            id,
            request: localCredential
          }) : await createImap({ request: localCredential })
          break
        default:
          res = null
        }

        if (res && res.status !== 200) {
          this.err = res?.response?.data?.statusText || (this.isEdit ? this.$lang.errors.credentialUpdate : this.$lang.errors.credentialCreate)
          setTimeout(() => this.err = '', 5000)
          this.loading = false
          this.lock = false

          return
        }
        this.success = this.isEdit ? this.$lang.success.credentialUpdated : this.$lang.success.credentialCreated

        this.lock = false

        if (!this.isEdit && res) {
          this.credential = res.data.data
          if (!this.credential.adminOptions) this.credential.adminOptions = { queryConsole: false }
          this.isEdit = true
          this.$router.push({
            name: 'credentialEdit',
            params: {
              id: res.data.data.id
            }
          })
        } else {
          this.afterSave(res?.data?.data)
        }

      } catch (err) {
        this.err = err
        this.loading = false
        this.lock = false
        setTimeout(() => this.err = '', 5000)
      }
    },
    async changeStatus() {
      this.loading = true
      this.lock = true
      try {
        const res = this.credential.status === 'ACTIVE' ? await disableCredential({ id: this.credential.id }) : await enableCredential({ id: this.credential.id })

        if (res.status !== 200) {
          this.err = this.$lang.errors.credentialStatusUpdate
          setTimeout(() => this.err = '', 5000)
          this.lock = false

          return
        }
        this.success = this.credential.status !== 'ACTIVE' ? this.$lang.success.credentialActivated : this.$lang.success.credentialDeactivated

        this.credential.status = res.data.data.status

        this.loading = false
        this.lock = false

        setTimeout(() => this.success = '', 5000)

      } catch (err) {
        this.err = err
        this.loading = false
        this.lock = false
        setTimeout(() => this.err = '', 5000)
      }
    }
  }
}
</script>
<style>
.full-width:nth-child(2) {
  min-width: 100%;
}
</style>
